body, html {
    height: 100%;
    margin: 0;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  #footer {
    margin-top: auto;
    background-color: #f0f0f0; /* Optional: Adjust to your preferred background color */
    padding: 1rem 0;
  }