.overlay-text {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  max-width: 40%;
}

.overlay-text h1 {
  font-size: 2.5rem;
}

.overlay-text p {
  font-size: 1.25rem;
}

#top-section {
  background-color: #D9D9D9;
}

#top-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.text-justify {
  text-align: justify;
}

.introduction-div {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#client-opinions {
  color: #79021C;
}

.column-divider-right::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 50%; /* Adjust this value to change the height of the line */
  background-color: white;
}

.column-divider-left::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 50%; /* Adjust this value to change the height of the line */
  background-color: white;
}

@media (max-width: 767.98px) { 
  .column-divider-right::after,
  .column-divider-left::before {
    display: none;
  }
  .column-divider-bottom::before {
    content: '';
    position: absolute;
    bottom: -1px; /* Adjust to ensure visibility */
    left: 50%;
    transform: translateX(-50%);
    width: 50%; /* Adjust this value to change the width of the line */
    height: 2px;
    background-color: white;
  }
  .column-divider-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 50%; /* Adjust this value to change the width of the line */
    height: 2px;
    background-color: white;
    display: block;
  }
}