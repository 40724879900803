.list-item {
    display: flex;
    align-items: center;
  }
  
  .list-item-text {
    text-decoration: none;

    word-break: break-word;
  }

  .list-item-icon {
    margin-right: 0.5rem; /* Ensure space between icon and text */
    flex-shrink: 0; /* Prevent the icon from shrinking */
  }

  .list-item-text-container {
    margin-left: 0.7rem; /* Ensure space between icon and text */
    flex-grow: 1; /* Allow the text container to take up the remaining space */
    overflow-wrap: break-word; /* Break long words to fit within the container */
  }
  
  .text-justify {
    text-align: justify;
  }