.carousel-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.carousel-image {
  max-width: 100%;
  height: 200px;
  object-fit: contain;
  display: block;
  margin: auto;
}
