.profile-header {
    background-color: #79021C;
    color: white;
}

.profile-picture{
    max-width: 170px;
    border-style: solid;
    border-color: white;
    border-width: 5px;
}

.text-justify {
    text-align: justify;
  }