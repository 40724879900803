.list-item {
    display: flex;
    align-items: center;
  }
  
  .list-item-text {
    text-decoration: none;
    word-break: break-word;
  }

  .list-item-icon {
    margin-right: 0.5rem; /* Ensure space between icon and text */
    flex-shrink: 0; /* Prevent the icon from shrinking */
  }

  .text-justify {
    text-align: justify;
  }