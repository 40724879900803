#nav-bar {
  background-color: #79021C;
}
#basic-nav-dropdown {
  color: white !important;
}

.nav-bar-link {
  color: white !important;
}

#brand {
  color: white;
}

@media (max-width: 575.98px) {
  .dropdown-item {
    white-space: normal;
    word-wrap: break-word;
  }
}

.custom-toggler {
  border-color: white !important;
}

.custom-toggler .navbar-toggler-icon {
  
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.custom-toggler:focus, .custom-toggler:active {
  border-color: white !important; 
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5); 
  outline: none !important;
}

.navbar-lead {
  font-size: 1.25rem;
  font-weight: 500;
}