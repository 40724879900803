/* Primary Button */
.btn.custom-btn-primary {
  border-width: 2px;
  background-color: #79021C;
  border-color: #79021C;
  color: white;
}

.btn.custom-btn-primary:hover {
  background-color: #79021C;
  border-color: #79021C;
}

.btn.custom-btn-primary:active {
  background-color: #79021C;
  border-color: #79021C;
}

/* Secondary Button */
.btn.custom-btn-secondary {
  border-width: 2px;
  background-color: white;
  border-color: #79021C;
  color: #79021C;
}

.btn.custom-btn-secondary:hover {
  background-color: #79021C;
  border-color: white;
  color: white;
}

.btn.custom-btn-secondary:active {
  background-color: #79021C;
  border-color: white;
  color: white;
}
