.same-height-header {
  background-color: #79021C;
  color: white;
  height: 150px; /* Adjust this height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-group-flush {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.list-group-item {
  flex: 1;
  display: flex;
  align-items: center;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}